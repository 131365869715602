export const NO_OF_MAGAZINES = 3;

export const NO_OF_PRODUCTS = 6;

export const NO_OF_PAGES = 6;

export const CTA_CONTAINER_TYPES = {
  CTA_BLOCK: 'CTABlock',
  CTA_IMAGE_BLOCK: 'CTAImageBlock',
  TEXT_BLOCK: 'TextBlock',
};

export const BLOCK_TYPES = {
  CONTACT_US_BLOCK: 'ContactUsBlock',
};
