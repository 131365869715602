import { styled } from 'Theme/stitches.config';
import CTAContainerModel from 'Models/Blocks/CTAContainer/CTAContainerModel.interface';
import CTABlock from '../CTABlock/CTABlock';
import { BackgroundColorVariants } from 'DesignSystem/Colors/BackgroundColors';
import CTABlockModel from 'Models/Blocks/CTABlock/CTABlockModel.interface';
import CTAImageBlockModel from 'Models/Blocks/CTAImageBlock/CTAImageBlockModel.interface';
import CTAImageBlock from '../CTAImageBlock/CTAImageBlock';
import ICTAContainerBlockModel from 'Models/Blocks/CTAContainer/ICTAContainerBlockModel.interface';
import { CTA_CONTAINER_TYPES } from 'Shared/Constants/common';
import { useBlockData } from '../../../routing/block.context';
import { BlockRenderer } from '../../../routing/BlockRenderer';

// TODO: This seems hacky
function CTAContainerBlock() {

  const { items } = useBlockData<CTAContainerModel>();

  const renderContentBlock = (block: any) => {
    switch (block?.containerType) {
      case CTA_CONTAINER_TYPES.TEXT_BLOCK:
        return <BlockRenderer blockType="TextBlock" content={{ ...block, bodyText: block.text }} />;

      case CTA_CONTAINER_TYPES.CTA_BLOCK:
        return <CTABlock content={block as CTABlockModel} size="m" />;

      case CTA_CONTAINER_TYPES.CTA_IMAGE_BLOCK:
        return <CTAImageBlock content={block as CTAImageBlockModel} />;

      default:
        return null;
    }
  };

  return (
    <Root>
      <Wrapper>
        {items.map((block: ICTAContainerBlockModel, index: number) => {
          return (
            <ColumnContainer key={index} hasOneCol={items.length === 1}>
              {renderContentBlock(block)}
            </ColumnContainer>
          );
        })}
      </Wrapper>
    </Root>
  );
}

const Root = styled('section', {
  py: '$s200',
  '@bpMin721': {
    py: '$s300',
  },
  variants: {
    ...BackgroundColorVariants.variants,
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  g: '24px',
  '@bpMin721': { g: '32px' },
});

const ColumnContainer = styled('div', {
  width: '100%',
  '@bpMin721': {
    width: 'calc(50% - 32px)',
  },
  variants: {
    hasOneCol: {
      true: {
        width: '100%',
      },
    },
  },
});

export default CTAContainerBlock;
